<template>
  <div>
    <title>SIM INVENTORY ~ ADD DATA PART COMPONENT</title>
    <section class="content-header">
      <h1>
        Add Data Part Component
        <br />
        <h4>Please enter the master part component data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Master Part Component</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Data Part Component</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Code / Part Number </label>
                  <input
                    type="text"
                    v-model="isidata.kodepart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name</label>
                  <input
                    type="text"
                    v-model="isidata.namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notespart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/part">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        kodepart: "",
        namapart: "",
        notespart: ""
      }
    };
  },
  methods: {
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodepart;
      var nama = this.isidata.namapart;
      var notes = this.isidata.notespart;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          part_no: this.isidata.kodepart,
          name: this.isidata.namapart,
          notes: this.isidata.notespart,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/part_component/savepart_component";
        const urlAPISaveData =
          this.$apiurl + "part_component/savepart_component";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodepart = "";
              this.isidata.namapart = "";
              this.isidata.notespart = "";
              this.loading = false;
              this.$router.push({ name: "part" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "part" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
